.map-data {
    &_controls-container {
        padding: 6px 8px;
        font: 14px/16px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        z-index: 999;

        h4 {
            margin: 0 0 5px;
            color: #777;
        }
    }
}
