.locations-filter {
    &_date-btn {
        margin: 10px 10px 0 0;
    }

    &_calendar-container {
        margin: 10px 0 0 0;
        width: 300px;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
    }
}
