@import '~antd/dist/antd.css';

.logo {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
    }

    img {
        display: inline-block;
        height: 32px;
        vertical-align: middle;
    }

    h1 {
        display: inline-block;
        height: 32px;
        margin: 0 0 0 12px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        vertical-align: middle;
    }
}

.site-layout-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    padding: 24px;
    background: #fff;
}
