.locations-map {
    &_map-container {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    &_map-frame {
        height: 100%;
        width: 100%;

        .leaflet-container {
            width: 100%;
            height: 100%;
        }
    }
}
